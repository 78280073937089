@tailwind base;
@tailwind components;
@tailwind utilities;

html {
  scroll-behavior: smooth;
}


.stagger-visualizer {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
}

.stagger-visualizer div {
  /*position: absolute;*/
  width: 1rem;
  height: 1rem;
  border: 1px solid #1E5FF1;
  background-color: #1E5FF1;
}

p {
  @apply
    mt-6
    text-lg
    text-gray-700;
}

h1 {
  @apply
    text-5xl
    font-bold
    tracking-tight
    text-gray-900
    sm:text-7xl;
}

h2 {
  @apply
    mt-2
    text-pretty
    text-4xl
    font-semibold
    tracking-tight
    text-gray-900
    sm:text-5xl;
}

h3 {
  @apply
    mt-6
    text-xl/8
    font-semibold
    text-[#1E5FF1];
}
h4 {
  @apply
    text-base
    font-semibold
    text-[#1E5FF1];
}

ul {
  @apply
  mt-6
  ml-4
  list-disc
  pl-4;
}
li {
  @apply
  mb-2
  text-lg
  text-gray-700;;
}